<template>
    <!-- :class="viewClass" -->
    <!-- class="pt-3 pt-sm-5" -->
    <div style="min-height: calc(100vh + 10px)">
        <!-- Логика процесса (инклудим в самом начале)-->
        <!-- isProd -->
        <div v-if="installerPermit">
            <div v-if="flowLogicIndex === '1'">
                <FlowLogic1
                    v-if="!SUBSCRIBE"
                ></FlowLogic1>
            </div>
            <div v-else-if="flowLogicIndex === '2'">
                <FlowLogic2
                    v-if="!SUBSCRIBE"
                ></FlowLogic2>
            </div>
        </div>

        <!-- Пуши -->
         <OneSignal></OneSignal>

        <!-- ----------- Нет пва, но есть фуллскрин --------- -->
         <ScreenModal v-if="fullscreenDialogTrigger"
            v-bind:target="'fullscreen'"
            v-bind:delayTime="1500"
            v-bind:dialogReady="true"
         ></ScreenModal>

        <!-- Нет пва и нет фуллскрина  -->
        <!-- <ScreenModal v-if="prelandDialogTrigger"
            v-bind:target="'preland'"
            v-bind:delayTime="1500"
            v-bind:dialogReady="true"
            v-on:extendsHide="extendsHide"
         ></ScreenModal> -->


        <!-- Бегущая строка -->
        <TickerBlock v-if="apiReady && !STANDALONE && extendsShow"
            v-bind:dataFeed="'mixed'"
            v-bind:goalName="'TickerBlock'"
            v-bind:unrefreshable="true"
        ></TickerBlock>

        <!-- Верхний блок -->
        <section v-if="apiReady && !STANDALONE && extendsShow">
            <b-container>
                <b-row>
                    <b-col class="d-flex justify-content-center">
                        <BlockJumbotron 
                            v-bind:dataFeed="'mixed'"
                            v-bind:goalName="'TopBlock'"
                        ></BlockJumbotron>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Контент-блок -->
        <section v-if="apiReady && !STANDALONE && extendsShow" class="bg-light pb-4 pb-lg-5">
            <b-container>
                <b-row>
                    <b-col>
                        <PostContent 
                            v-on:goPreLand="goPreLand"
                            v-bind:delayTime="delayTime"
                        ></PostContent>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!--  -->
        <div v-if="apiReady && !STANDALONE && extendsShow">
            <!-- Нужна зависимость от высоты экрана 760, и чтобы при ресайзе реактивилась -->
            <!-- маркер перед слайдером -->
            <div v-if="stickyCondition" class="w-100 d-block bg-white" style="height: 1px;" v-b-visible="wrapperScrollUp"></div>
            <!-- Слайдер -->
            <section v-if="!isPreLandPage" :class="classSliderSection" :style="styleSectionSlider">
                <!-- маркер прокрутки -->
                <div v-if="screenHeight >= 760" class="w-100 d-block bg-white" style="height: 1px;" v-b-visible="sliderScrollUp"></div>                
                <b-container>
                    <b-row>
                        <b-col>
                            <CarouselBlock 
                                v-bind:goalName="'CarouselBlock'"
                                v-bind:dataFeed="'mixed'"
                                v-bind:noName="true"
                                class="mb-3"
                            >
                            </CarouselBlock>
                        </b-col>
                    </b-row>
                </b-container>
            </section>

            <!-- половинчатый блок -->
            <section class="bg-light pb-2">
                <b-container class="pt-3 pt-md-4">
                    <b-row class="py-lg-4">
                    <b-col class="col-12 col-lg-6">
                        <SimpleBlock 
                        v-bind:dataFeed="'mixed'"            
                        v-bind:infinity="false"
                        v-bind:autoCount="false"
                        v-bind:limit="4"
                        v-bind:goalName="'HalfBlock'"
                    ></SimpleBlock>
                    </b-col>
                    <b-col class="col-12 col-lg-6">
                        <SimpleBlock 
                        v-bind:dataFeed="'mixed'"            
                        v-bind:infinity="false"
                        v-bind:autoCount="false"              
                        v-bind:limit="4"
                        v-bind:goalName="'HalfBlock'"
                        ></SimpleBlock>
                    </b-col>        
                    </b-row>
                </b-container>
            </section>

            <!-- блок "текст поверх картинки" -->
            <section v-if="!STANDALONE" class="bg-dark" style="border-bottom: 1px solid white;">
                <b-container>
                    <b-row>
                        <b-col class="px-md-4 px-lg-5">
                            <OverlayBlock
                                v-bind:goalName="'OverlayBlock1'"
                                v-bind:infinity="false"
                                v-bind:limit="6"
                                v-bind:dataFeed="'mixed'"
                                v-bind:border="'info'"
                                v-bind:noName="true"
                            ></OverlayBlock>
                        </b-col>
                    </b-row>
                </b-container>
            </section>            
        </div>

        <!-- текстовый блок -->
        <section v-if="apiReady && !STANDALONE && extendsShow" class="pt-3 overflow-hidden">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlockFooter'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:limit="16"
                            v-bind:icon="'newspaper'"
                            v-bind:hoverable="true"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>        
        <!-- бесконечный -->
        <section v-if="apiReady && !STANDALONE">
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlockInfinity'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'mixed'"
                            v-bind:noName="true"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section> 

        <!-- Тостер -->
         <div v-if="!STANDALONE && extendsShow">
            <!-- Почему-то, если разместить не НАД, а ПОД следующим блоком, то OverlayBlock ломается - это необъяснимая загадка  -->
            <!-- поэтому пусть тут стоит -->
            <!-- && isProd -->
            <ToastBlock v-if="!isDirectHit"
                v-bind:dataFeed="'mixed'"
                v-bind:limit="4"
                v-bind:goalName="'Toast'"
                v-bind:timeInterval="15000"
                v-bind:unrefreshable="true"
            ></ToastBlock>                 
         </div>

        <!-- В стандалон-моде для яндекс-браузера -->
        <section v-show="STANDALONE" class="bg-dark" id="app-alternate-block">
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'mixed'"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Мобильный пуш -->
        <!-- <MobilePush></MobilePush> -->

        <!-- префетчим список постов -->
        <!-- <PostPrefetch v-if="needPrefetch && POSTS.length"
            v-bind:loadContent="!PREFETCHED_CONTENT"
            v-bind:loadMedium = "!PREFETCHED_MEDIUM"
            v-bind:loadMicro = "!PREFETCHED_MICRO"
        ></PostPrefetch> -->

    </div>
</template>


<script>
import utils from '../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import FlowLogic1 from '../components/flow/FlowLogic1.vue'
import FlowLogic2 from '../components/flow/FlowLogic2.vue'
import OneSignal from '../components/flow/OneSignal.vue'
import PostContent from '../components/content/PostContent'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import PostPrefetch from '../components/content/PostPrefetch'
import TickerBlock from '../components/integrations/TickerBlock'
import ToastBlock from '../components/integrations/ToastBlock.vue'
import BlockJumbotron from '../components/integrations/BlockJumbotron.vue'
import MobilePush from '../components/integrations/MobilePush.vue'
import OverlayBlock from '../components/integrations/OverlayBlock.vue'
import TextBlock from '../components/integrations/TextBlock'
import ScreenModal from '../components/flow/ScreenModal'
export default {
    components: {
        BContainer, 
        BRow, 
        BCol,        
        FlowLogic1,
        FlowLogic2,
        OneSignal,
        PostContent,
        CarouselBlock,
        SimpleBlock,
        PostPrefetch,
        TickerBlock,
        ToastBlock,
        BlockJumbotron,
        MobilePush,
        OverlayBlock,
        TextBlock,
        ScreenModal
    },    
    data(){
        return{
            flowLogicIndex: '',
            postsListAPI: utils.restAPIlist(),
            needPrefetch: false,
            isPreLandPage: false,  
            sliderIsSticky: true,
            sliderIsScrollingNow: true,
            screenHeight: 0,
            screenHeighStyckyBreakPoint: 1024,
            viewDifference: false,
            extendsShow: true,
        }
    },
    computed: {
        ...mapGetters(['SUBSCRIBE', 'PREFETCHED_LIST', 'PREFETCHED_CONTENT', 
        'PREFETCHED_MEDIUM', 'PREFETCHED_FULL', 'PREFETCHED_MICRO', 
        'LAST_ROUTE', 'POSTS', 'STANDALONE', 'API_INITED', 'MODEL0', 'MODEL1', 'PLATFORM', 'INSTALLABILITY', 'MIXED_READY', 'NEXT_POST_LIST']),
        aspect: function(){
            return (this.ACTIVE_NOW === 'infox') ? '4:3' : '1:1'
        },
        isDirectHit: function(){
            return !utils.hasReferrer()
        },
        isProd: function(){
            return utils.isProd()
        },
        styleSectionSlider: function(){
            return (this.sliderIsSticky && !this.sliderIsScrollingNow) ? 'box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.51)' : null
        },
        classSliderSection: function(){
            //return (this.screenHeight >= this.screenHeighStyckyBreakPoint) ? 'app-slider-section sticky-top bg-white' : null
            return null
        },
        stickyCondition: function(){
            // return (this.screenHeight >= this.screenHeighStyckyBreakPoint) ? true : false
            return false
        },
        apiFilter: function(){
            // return (((this.MODEL0 && this.PLATFORM !='desktop' && utils.hasReferrer()) ||  (this.MODEL0 && !utils.isProd())) && !document.cookie.match(/cf=1/i))
            //return (this.MODEL1 &&  (utils.hasReferrer() || !utils.isProd()) && !document.cookie.match(/cf=1/i))
            return false
        },
        delayTime: function(){
            return (this.apiFilter) ? 120000 : 0            
            // return 0
        },
        apiReady: function(){
            return (this.viewDifference) ? this.API_INITED : true
        },
        fullscreenDialogTrigger: function(){
            return (utils.fullScreenAbility() && !this.INSTALLABILITY && utils.hasReferrer())
        },
        prelandDialogTrigger: function(){
            return (!utils.fullScreenAbility() && !this.INSTALLABILITY && !document.cookie.match(/plActive=1/i) && utils.hasReferrer())
            //return (!document.cookie.match(/plActive=1/i))
            // return true
        },
        installerPermit: function(){
            return (utils.hasReferrer())
        }        
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'FETCH_INFOX', 'FETCH_LIST', 'TRACK_ROUTE', 'SMI2_FETCH', 'FEED_SUM_DEFINE', 'FEED_COUNT_RESET', 'GET_MODEL']),
        goPreLand: function(){
            this.isPreLandPage = true
        },
        sliderScrollUp: function(visible){
            this.sliderIsSticky = visible
        },
        wrapperScrollUp: function(visible){
           this.sliderIsScrollingNow = visible 
        },
        extendsHide: function(){
            console.log('extendsHide')
            this.extendsShow = false
        }
    },
    created: function(){
        //this.GET_MODEL(0)
        //this.GET_MODEL(1)
        this.FEED_COUNT_RESET()
        //дергаем тизерки
        const vueContext = this
        //ЗАКОММЕНТИТЬ||РАСКОММЕНТИТЬ
        if (utils.hasReferrer()){
            this.FEED_SUM_DEFINE(3)
            this.FETCH_SMI24(vueContext)
            this.FETCH_INFOX(vueContext)   
            this.SMI2_FETCH(vueContext)        
        }else{
            this.FEED_SUM_DEFINE(1)
            this.FETCH_SMI24(vueContext)
        }

    },    
    beforeMount: function(){
        this.flowLogicIndex = process.env.VUE_APP_LOGIC_VARIANT
        this.screenHeight = screen.height
        const vueContext = this
        window.addEventListener('resize', function(){
            vueContext.screenHeight = screen.height
        })
    },
    mounted: function(){
        const vueContext = this
        if (utils.fullScreenAbility()){
            utils.safeGoal('fullscreenEnable', vueContext)
        }else{
            utils.safeGoal('fullscreenDisable', vueContext)
        }
        if (this.fullscreenDialogTrigger){
            utils.safeGoal('fullscreenDialogTrigger', vueContext) 
        }
        if (this.prelandDialogTrigger){
            utils.safeGoal('marker2', vueContext) 
        }        
        if (this.STANDALONE){
            const vueContext = this
            utils.safeGoal('Standalone', vueContext)
        }
        // console.log('View mounted, this.MIXED_READY', this.MIXED_READY)
        // if (this.MIXED_READY){
        //     this.FETCH_LIST(true)
        // }        
    },
    beforeDestroy: function(){
        //трекаем маршрут
        this.TRACK_ROUTE(this.$route.name)
    },
    watch: {
        MIXED_READY: function(newVal, oldVal){
            if (newVal && !oldVal){
                // console.log('this.MIXED_READY', this.MIXED_READY)
                if (!this.NEXT_POST_LIST.length){
                    this.FETCH_LIST(true)
                }
                
            }
            
        }
    }
}
</script>

<style lang="sass">
$contentAnimationDuration: 2s
.app-content-preloader-wrap
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
//исчезновение прелоадера    
.disappear-leave
    opacity: 1
.disappear-leave-to
    opacity: 0    
.disappear-leave-active
    transition: opacity 0.3s
//появление контента
.appear-enter
    opacity: 0
.appear-enter-to
    opacity: 1    
.appear-enter-active
    transition: opacity 0.8s

.app-slider-section
    transition: all 0.6s    
</style>