<template>
    <div class="flex-grow-1">
        <b-button v-if="isRotator || (isHome && hasRef) || STANDALONE"
            variant="primary" 
            size="sm" 
            :disabled="this.REFRESHING" 
            @click="newsUpdate" 
            :class="buttonClass"
        >
            <span class="mr-2">Обновить</span>
            <b-icon icon="arrow-clockwise" style="transform: rotate(45deg)"></b-icon>                
        </b-button>

        <!-- <b-button v-else-if="!SUBSCRIBE" 
            variant="primary" 
            size="sm" 
            :disabled="buttonDisable" 
            :class="'app-install-trigger '+buttonClass"
            @click="subscribeClick"
        >
            <b-icon :icon="icon" class="mr-2"></b-icon>
            <span>Подписаться</span>
        </b-button> -->

        <!-- :class="'app-install-trigger '+buttonClass" -->
        <b-button v-if="isPost"
            variant="primary" 
            size="sm" 
            :disabled="buttonDisable" 
            :class="buttonClass"
            @click="nextButtonClick"
        >
            <!-- <b-icon icon="circle-fill" class="mr-2" animation="throb"></b-icon> -->
            <!-- fade -->
            <!-- variant="danger" -->
             <!-- <b-icon icon="circle-fill" class="mr-3" font-scale="0.75" :animation="animation"></b-icon> -->
            <span class="font-weight-bold">Следующая новость</span>
            <b-icon :icon="icon" :animation="animation" class="ml-2"></b-icon>

            <!-- <PostPrefetch
                v-if="MIXED_READY && nextId"
                v-bind:loadContent="true"
                v-bind:loadFull="true"
                v-bind:postId="nextId"
            ></PostPrefetch>             -->
        </b-button>        
    </div>    
</template>

<script>
import utils from '../../functions/functions'
import { mapGetters, mapActions } from 'vuex'
import {  BButton } from 'bootstrap-vue'
import PostPrefetch from '../content/PostPrefetch.vue'
export default {
    components: {
        BButton,
        PostPrefetch
    },
    data(){
        return {
            posts: [],
            buttonClass: 'd-flex justify-content-center align-items-center',
            currentId: utils.getIdFromAddressBar(),
            indexOfNextPost: 0,
            idCollection: [],
            buttonAnimation: false,
            animationPlayed: false,
            infoxToPostTransition: false,  //
            nextPostIndex: -1,
        }
    },    
    computed: {
        ...mapGetters(['FLOW_READY', 'SUBSCRIBE', 'REFRESHING', 'ACTIVE_NOW', 'POSTS', 'MIXED_READY', 'INFOX', 'STANDALONE', 'NEXT_POST_LIST', 'POST']),
        isRotator: function(){
            const vueContext = this
            return utils.isRotator(vueContext)
        },   
        isHome: function()     {
            const vueContext = this
            return utils.isHome(vueContext)
        },
        isPost: function(){
            const vueContext = this
            return utils.isPost(vueContext)            
        },
        hasRef: function(){
            return utils.hasReferrer()
        },
        refreshButtonCondition: function(){
            const vueContext = this
            return (utils.isRotator(vueContext) || (utils.isHome(vueContext) && utils.hasReferrer()))
        },
        buttonDisable: function(){
            return (!this.posts.length || !this.MIXED_READY || !this.POST.title)
        },
        icon: function(){
            //return (this.FLOW_READY) ? 'bell-fill' : 'chevron-double-right' //'lock-fill'
            return 'circle-fill' //'lock-fill'
        },
        nextId: function(){
            return (this.idCollection.length) ? this.idCollection[this.indexOfNextPost] : undefined
        },
        animation: function(){
            return (this.buttonAnimation) ? 'throb' : null
        },
        buttonAnimateConditions: function(){
            return ((utils.hasReferrer() || !utils.isProd()) && !this.animationPlayed)
        }
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'FETCH_INFOX', 'SMI2_FETCH', 'RESET_ALL', 'FEED_SUM_DEFINE', 'FEED_COUNT_RESET', 'RESET_POST', 'SET_POST', 
        'FETCH_LIST', 'FETCH_POST', 'TEXT_STATE_SET', 'FAKE_RESET']),
        newsUpdate: function(){
            this.RESET_ALL()
            this.FEED_COUNT_RESET()
            const feedSum = (this.isRotator) ? 2 : 3
            this.FEED_SUM_DEFINE(feedSum)
            const vueContext = this
            utils.safeGoal('ButtonClick', vueContext)
            this.FETCH_SMI24(vueContext)
            this.FETCH_INFOX(vueContext)
            if (this.isHome){
                this.SMI2_FETCH(vueContext)
            }
        }, 

        nextButtonClick: function(){
            //останавливаем мигание иконки
            this.buttonAnimation = false
            //сабмитим цель
            const vueContext = this
            utils.safeGoal('NextPost', vueContext)
            //схлопываем контент-блок
            if (utils.fullScreenAbility()){
                this.TEXT_STATE_SET(false)
            }
            //обновляем пост
            this.getNext()
            //перемешиваем тизерки
            this.FAKE_RESET()

            if (utils.fullScreenAbility()){
                utils.setFullscreen()
            }
            //убираем анимацию иконки
            // this.buttonAnimation = false
        },
        subscribeClick: function(){
            const vueContext = this
            utils.safeGoal('SubscribeButton', vueContext)
        },
        getNext: function(){
            const source =  this.posts
            let nextIndex = (this.nextPostIndex != source.length-1) ? this.nextPostIndex + 1 : 0
            let nextPostId = source[nextIndex].id
            const currentId = parseInt(utils.getIdFromAddressBar())
            //пропускаем если id совпадают
            if (nextPostId === currentId){
                nextIndex = (nextIndex != source.length) ? nextIndex + 1 : 0
                nextPostId = source[nextIndex].id
            }
            //фиксируем для следующей итерации
            this.nextPostIndex = nextIndex
            //обнуляем текущий пост
            this.RESET_POST(true)
            //пушим роут
            try {
                this.$router.push(`/post/?p=${nextPostId}`)
            }catch(e){
                utils.consoleLog('Ошибка при пушинге в роутер', e)
            }
            //заряжаем новый пост
            const postObj = {
                title: source[nextIndex].title,
                img: source[nextIndex].img,
                text: source[nextIndex].text,
                id: source[nextIndex].id,
            }
            //прокидываем во вьюекс
            const vueContext = this
            const updateTime = utils.randomInt(0, 500)
            window.setTimeout(function(){
                vueContext.SET_POST(postObj)
            }, updateTime) //updateTime
        },
    },
    mounted: function(){
        /*
        if (utils.hasReferrer() || !utils.isProd()){
            const vueContext = this
            window.setTimeout(function(){
                vueContext.buttonAnimation = true
            }, 2000)
        }
        */
    },
    watch: {
        NEXT_POST_LIST: function(){
            this.posts = this.NEXT_POST_LIST
        },
        buttonDisable: function(newVal, oldVal){
            // console.log('this.buttonAnimateConditions', this.buttonAnimateConditions)
            if (this.buttonAnimateConditions && !newVal && oldVal){
                // console.log('ща будем в таймаут заряжать')
                const vueContext = this
                window.setTimeout(function(){
                    vueContext.buttonAnimation = true
                }, 2000)                
            }
        },
        animation: function(newVal, oldVal){
            if (!!newVal && !oldVal){
                //ставим флаг
                this.animationPlayed = true
                //пущим в метрику
                const vueContext = this
                utils.safeGoal('buttonAnimate', vueContext)
            }
            // console.log('this.animation', this.animation)
        }
    }
}
</script>

<style lang="sass" scoped>
button
    width: 100%
    span
        font-weight: 500
</style>