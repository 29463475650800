<template>
    <div></div>
</template>

<script>

import { mapGetters } from 'vuex'
import utils from '../../functions/functions'

export default {
    data(){
        return {
            cdnPath: 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js',
            appId: 'd8b5b0db-9c96-4de8-9075-76448d95f174',
            safari_web_id: 'web.onesignal.auto.00e855ed-5f66-45b8-ad03-54b1e142944e'
        }
    },
    computed: {
        ...mapGetters(['PLATFORM']),
        oneSignalStart: function(){
            // return (this.$route.query.p && this.$route.query.p === '385163')
            return (this.PLATFORM === 'mobile')
        },
        testPage: function(){
            return (this.$route.query.p && this.$route.query.p === '385163')
        }
    },
    created: function(){
        if(this.oneSignalStart){
            //заинитили
            this.$OneSignal.init({ appId: this.appId })
            
            //сабмитим цели
            const vueContext = this
            //показ
            this.$OneSignal.Notifications.addEventListener('permissionPromptDisplay', function(e){
                utils.safeGoal('permissionPromptDisplay', vueContext)
                // console.log('permissionPromptDisplay', e)
            })
            //подписка|неподписка
            this.$OneSignal.Notifications.addEventListener('permissionChange', function(e){
                const goalName = (e) ? 'pushAccept' : 'pushDecline'
                utils.safeGoal(goalName, vueContext)
            })

            //показываем нотификатор
            this.$OneSignal.User.PushSubscription.optIn()
            // console.log('OneSignal', OneSignal)
        }
    }
}
</script>
