<template>
  <div id="app">

      <NavLine></NavLine>
      <!-- кнопка sticky -->
      <div v-if="SCREEN_SIZE === 1" class="sticky-top d-flex bg-dark w-100 p-2">
          <NavLineButton></NavLineButton>
      </div>    


    <!-- с анимацией -->
    <transition name="fade-delayed">
      <router-view/>
    </transition>

    <SidebarLeftBlock v-if="!isRotator && !STANDALONE" 
      v-bind:dataFeed="'mixed'" 
      v-bind:goalName="'SidebarClick'"
      v-bind:unrefreshable="true"
    ></SidebarLeftBlock>

    <FooterLine v-if="!isRotator"></FooterLine>    
    <OfflineIndicator></OfflineIndicator>

  </div>
</template>

<script>
import frowser from "frowser"
import { mapActions, mapGetters } from 'vuex'
import utils from './functions/functions'
import OfflineIndicator from './components/dev/OfflineIndicator.vue'
import NavLine from './components/general/NavLine'
import RotatorNav from './components/rotator/RotatorNav'
import FooterLine from './components/general/FooterLine'
import SidebarLeftBlock from './components/integrations/SidebarLeftBlock.vue'
import NavLineButton from './components/general/NavLineButton.vue'
export default {
  components: {
    NavLine,
    NavLineButton,
    FooterLine,
    RotatorNav,
    OfflineIndicator,
    SidebarLeftBlock
  },
  computed: {
    ...mapGetters(['INSTALLABILITY', 'SCREEN_SIZE', 'STANDALONE', 'PLATFORM']),
    isRotator: function(){
      const vueContext = this
      return (utils.isRotator(vueContext)) ? true : false
    },
    /*
    oneSignalStart: function(){
      // return (this.$route.query.p && this.$route.query.p === '385163')
      return (this.PLATFORM === 'mobile')
    }
    */
  },
  methods: {
    ...mapActions(['SCREEN_CHECK', 'SUBSCRIBE_CHECK', 'SESSION_START', 'SET_BROWSER', 'SET_OS', 'SET_PLATFORM', 
    'INSTALLABILITY_CHECK', 'FULLSCREEN_CHANGE', 'STANDALONE_CHECK'])
  },
  beforeCreate: function(){
  },
  created: function(){
    const vueContext = this
    document.addEventListener("fullscreenchange", function() {
      const data = {
        fullscreenAtcive: (document.fullscreenElement) ? true : false,
        vueContext: vueContext
      }
      vueContext.FULLSCREEN_CHANGE(data)
    })
    document.addEventListener("mozfullscreenchange", function() {
      const data = {
        fullscreenAtcive: (document.fullscreenElement) ? true : false,
        vueContext: vueContext
      }
      vueContext.FULLSCREEN_CHANGE(data)
    })
    document.addEventListener("webkitfullscreenchange", function() {
      const data = {
        fullscreenAtcive: (document.fullscreenElement) ? true : false,
        vueContext: vueContext
      }
      vueContext.FULLSCREEN_CHANGE(data)
    })
    document.addEventListener("msfullscreenchange", function() {
      const data = {
        fullscreenAtcive: (document.fullscreenElement) ? true : false,
        vueContext: vueContext
      }
      vueContext.FULLSCREEN_CHANGE(data)
    })
    this.STANDALONE_CHECK()
    this.SESSION_START()
    this.SCREEN_CHECK()
    this.INSTALLABILITY_CHECK()

    const userProps = frowser.getParser(window.navigator.userAgent)
    const browser = userProps.getBrowser()
    this.SET_BROWSER(browser.name)
    const os = userProps.getOS()
    this.SET_OS(os.name)
    const platform = userProps.getPlatform()
    this.SET_PLATFORM(platform.type)    

    window.addEventListener('resize', function(){
      vueContext.SCREEN_CHECK()
      //чтобы менялось при эмуляции в процессе тестирования
      const userProps = frowser.getParser(window.navigator.userAgent)
      const browser = userProps.getBrowser()
      vueContext.SET_BROWSER(browser.name)
      const os = userProps.getOS()
      vueContext.SET_OS(os.name)
      const platform = userProps.getPlatform()
      vueContext.SET_PLATFORM(platform.type)          
    })
  },
  beforeMount: function(){
    /*
    if(this.oneSignalStart){
      //инитим
      this.$OneSignal.init({ appId: 'd8b5b0db-9c96-4de8-9075-76448d95f174' })
      //показываем нотификатор
      this.$OneSignal.User.PushSubscription.optIn()
      // console.log('OneSignal', OneSignal)
    }
    */
  },
  mounted: function(){
    const installabilityGoalName = (this.INSTALLABILITY) ? 'installabilityTrue' : 'installabilityFalse'
    const vueContext = this
    utils.safeGoal(installabilityGoalName, vueContext)
    utils.safeGoal('wv', vueContext)
  },
}
</script>

<style lang="sass">
// global
.text-black
  color: black !important
body
  background: white
nav
  padding: 30px
  a
    font-weight: bold
    color: #2c3e50

    &.router-link-exact-active
      color: #42b983

.fade-delayed-leave-active
  transition: opacity .2s
.fade-delayed-enter-active 
  transition: opacity 0.15s ease-in 0.2s
.fade-delayed-enter, .fade-delayed-leave-to
  opacity: 0

.fade-leave-active
  transition: opacity .2s
.fade-enter-active 
  transition: opacity 0.15s ease-in
.fade-enter, .fade-leave-to
  opacity: 0  

.fade-append-leave-active
  transition: opacity .2s
.fade-append-enter-active 
  transition: opacity 0.15s ease-in
.fade-append-enter //, .fade-delayed-leave-to
  opacity: 0

//нормально ща будет
.append-enter-from
  opacity: 0  
.append-enter-to
  opacity: 1    
.append-enter-active
  transition: opacity 0.2s

//анимация всех блоков
@keyframes block-append
  0%
    opacity: 0
    // border: 1px dotted red
  100%
    opacity: 1
    // border: 1px dotted blue
.app-block-append  
  animation-name: block-append
  animation-duration: 0.2s
.app-preloader-append  
  animation-name: block-append
  animation-duration: 0.4s
</style>
