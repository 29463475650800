import utils from '../functions/functions'
export default{
    state: {
        smi24: [],
        infox: [],
        smi2: [],
        mixed: [],
        mixedReserve: [],
        inProgress: false,
        activeNow: '',
        feedSum: undefined,
        feedCount: 0
    },
    actions: {
        FAKE_RESET(context){
            // console.log('FAKE_RESET')
            context.commit('FAKE_UPDATE')
        },
        FEED_COUNT_RESET(context){
            context.commit('FEED_COUNT_UPDATE_TO_DEFAULT')
        },
        FEED_SUM_DEFINE(context, sum){
            context.commit('FEED_SUM_UPDATE', sum)
        },
        RESET_ALL(context){
            const empty = []
            context.commit('UPDATE_ALL', empty)
        },        
        async SMI2_FETCH(context, vueContext){
            const newsBlocks = [] 
            context.commit('FETCHING_START')
            try {
                const realFeed = 'https://smi2.ru/data/js/102543.js'
                const feedUrl = (utils.localResources()) ? 'http://localhost/content/mock/smi2/' : realFeed
                const res = await fetch(feedUrl, {
                    //параметры для теста
                    // mode: 'no-cors'
                })
                if (!res.ok){
                    utils.safeGoal('ErrorSmi2', vueContext)
                }
                const blocks = (res.ok) ? await res.json() : {news:[]}
                for (let i=0; i<blocks.news.length; i++){
                    newsBlocks[i] = blocks.news[i]
                    newsBlocks[i].name = 'smi2.ru'
                    const imgMock = 'http://localhost/content/mock/smi2/image/'
                    newsBlocks[i].img = (utils.localResources()) ? imgMock : newsBlocks[i].img
                }
            }catch(e){
                console.log('catched', e)
                utils.safeGoal('ErrorSmi2', vueContext)                
            }
            const result = {
                array: newsBlocks,
                name: 'smi2'
            }
            context.commit('UPDATE_FEED', result)
            context.commit('FETCHING_END')
        },
        async FETCH_SMI24(context, vueContext){
            context.commit('FETCHING_START')
            const newArr = []
            try {
                //0 - local, 1 = prod
                const devProdIndex = (utils.isProd()) ? 1 : 0
                //0 - direct, 1 - ref
                const directRefIndex = (utils.hasReferrer()) ? 1 : 0

                let smi24IDstring = process.env.VUE_APP_SMI24_LANDING
                if (utils.isRotator(vueContext)){
                    smi24IDstring = process.env.VUE_APP_SMI24_ROTATOR
                }
                const smi24IDarray = smi24IDstring.split(',')
                const smi24ID = smi24IDarray[directRefIndex]
                // const smi24ID = smi24IDarray[devProdIndex]
                const urlBase = '//data.24smi.net/informer?object='
                const feedUrl = (utils.localResources()) ? 'http://localhost/content/mock/smi24/' : `${urlBase}${smi24ID}&output=json&num=49`
                const res = await fetch(feedUrl, {
                    // credentials: 'include', //same-origin (by default)
                    // credentials: (utils.isProd()) ? 'include' : 'same-origin'
                    //для теста
                    // mode: 'no-cors'
                })
                const blocks = (res.ok) ? await res.json() : {news:[]}
                if (!res.ok){
                    utils.safeGoal('Error24smi', vueContext)
                }
                if (blocks.news.length){
                    for (let i=0; i<blocks.news.length; i++){
                        if (blocks.news[i].name || utils.isRotator(vueContext)){
                            blocks.news[i].url = (utils.isProd()) ? blocks.news[i].url : 'https://24smiasdfsadfsdfads.com'
                            blocks.news[i].img = (utils.localResources()) ? 'http://localhost/content/mock/smi24/image/' : blocks.news[i].img
                            blocks.news[i].rgb = blocks.news[i].icp
                            newArr.push(blocks.news[i])
                        }
                    }
                }                 
            }catch(e){
                console.log('catched', e)
                utils.safeGoal('Error24smi', vueContext)
            }
            const result = {
                array:  newArr, 
                name: 'smi24'
            }
            context.commit('UPDATE_FEED', result)
            context.commit('FETCHING_END')            
        },
        async FETCH_INFOX(context, vueContext){
            context.commit('FETCHING_START')
            const newArr = []
            try {
                const devProdIndex = (utils.isProd()) ? 1 : 0
                const infoxIDstring = (utils.isRotator(vueContext)) ? process.env.VUE_APP_INFOX_ROTATOR : process.env.VUE_APP_INFOX_LANDING
                const infoxIDarray = infoxIDstring.split(',')
                const infoxID = infoxIDarray[devProdIndex]
                const realFeed = `//rb.infox.sg/json?id=${infoxID}&external=true`
                const feedUrl = (utils.localResources()) ? 'http://localhost/content/mock/infox/' : realFeed
                const res = await fetch(feedUrl, {
                    // credentials: 'omit'
                    // credentials: 'include', //same-origin (by default)
                    credentials: (utils.isProd()) ? 'include' : 'same-origin'                    
                })
                const blocks = (res.ok) ? await res.json() : []
                if (!res.ok){
                    utils.safeGoal('ErrorInfox', vueContext)
                }
                const max = (utils.isRotator(vueContext)) ? (blocks.length - 10) : 5
                for (let i=0; i<max; i++){
                    const img = new Image()
                    const imgMock = 'http://localhost/content/mock/infox/image/'                    
                    img.async = true
                    img.src = (utils.localResources()) ? imgMock+'?zero_pixel' : blocks[i].zero_pixel                    
                    blocks[i].url = (utils.isProd()) ? blocks[i].url : 'https://infoxasdfsadfsdfads.com'
                    blocks[i].name = blocks[i].source
                    blocks[i].img = (utils.localResources()) ? imgMock : blocks[i].img
                    blocks[i].rgb = blocks[i].bg_rgb 
                    newArr.push(blocks[i])
                }
            } catch(e) {
                console.log('error', e)
                utils.safeGoal('ErrorInfox', vueContext)
            }
            const result = {
                array:  newArr, 
                name: 'infox'
            }
            context.commit('UPDATE_FEED', result)
            context.commit('FETCHING_END')            
        }
    },
    mutations: {
        FEED_COUNT_UPDATE_TO_DEFAULT(state){
            state.feedCount = 0
        },
        FEED_SUM_UPDATE(state, sum){
            state.feedSum = sum
        },
        UPDATE_ALL(state, val){
            state.mixed = val
            state.infox = val
            state.smi24 = val
            state.smi2 = val
        },
        UPDATE_FEED(state, result){
            state.activeNow = result.name
            state[result.name] = result.array
            state.mixed = state.mixed.concat(result.array)
            state.feedCount++
        },
        FETCHING_START(state){
            state.inProgress = true
        },
        FETCHING_END(state){
            state.inProgress = false
        },
        FAKE_UPDATE(state){
            state.mixedReserve = state.mixed
            state.mixed = []
            state.inProgress = true
            const updateTime = utils.randomInt(300, 1500)
            // console.log('updateTime', updateTime)
            window.setTimeout(function(){
                state.mixed = state.mixedReserve
                state.inProgress = false
            }, updateTime)
        }
    },
    getters: {
        MIXED(state){
            return state.mixed
        },
        SMI24(state){
            return state.smi24
        },
        SMI2(state){
            return state.smi2
        },        
        INFOX(state){
            return state.infox
        },
        ACTIVE_NOW(state){
            return state.activeNow
        },
        REFRESHING(state){
            return state.inProgress
        },
        MIXED_READY(state){
            return (state.feedCount === state.feedSum && state.mixed.length && !state.inProgress)
        }
    }
}