<template>
  <div>
    <!-- слайдер -->
    <!-- mt-sm-5 -->
    <section class="mb-4 mt-0" v-if="!hasRef">
      <b-container>
        <b-row>
          <b-col>
            <CarouselBlock
              v-bind:dataFeed="dataFeed.CarouselBlock"
              v-bind:limit="12"
              v-bind:goalName="goalName"
            ></CarouselBlock>    
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- половинчатый блок -->
    <section class="bg-light pb-2" v-if="!hasRef">
      <b-container class="pt-3 pt-md-4">
        <b-row class="py-lg-4">
          <b-col class="col-12 col-lg-6">
            <SimpleBlock 
              v-bind:dataFeed="dataFeed.SimpleBlock"            
              v-bind:infinity="false"
              v-bind:autoCount="false"
              v-bind:limit="3"
              v-bind:goalName="goalName"
          ></SimpleBlock>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <SimpleBlock 
              v-bind:dataFeed="dataFeed.SimpleBlock"            
              v-bind:infinity="false"
              v-bind:autoCount="false"              
              v-bind:limit="3"
              v-bind:goalName="goalName"
            ></SimpleBlock>
          </b-col>        
        </b-row>
      </b-container>
    </section>

    <!-- текстовый блок для hasRef-->
    <section class="pt-3" v-if="hasRef">
        <b-container>
            <b-row>
                <b-col>
                    <TextBlock
                        v-bind:goalName="'ClickFromHome'"
                        v-bind:dataFeed="'mixed'"
                        v-bind:limit="60"
                        v-bind:icon="'newspaper'"
                        ></TextBlock>            
                </b-col>
            </b-row>
        </b-container>
    </section>

    <!-- блок с оверлеями -->
    <section class="pt-2">
      <b-container>
        <b-row>
          <b-col>
            <OverlayBlock 
              v-bind:dataFeed="dataFeed.OverlayBlock"
              v-bind:limit="limit"
              v-bind:goalName="goalName"
              v-bind:infinity="!siteContent"
            ></OverlayBlock>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- Тостер -->
    <ToastBlock
      v-if="!isDirectHit"
      v-bind:dataFeed="dataFeed.ToastBlock"
      v-bind:limit="4"
      v-bind:goalName="'Toast'"
      v-bind:timeInterval="15000"
    ></ToastBlock>

    <!-- префетчинг постов -->
    <!-- needPrefetch &&  -->
    <PostPrefetch v-if="needPrefetch && POSTS.length"
      v-bind:loadContent="!this.PREFETCHED_CONTENT"
      v-bind:loadFull="!this.PREFETCHED_FULL"
    ></PostPrefetch>

  </div>
</template>

<script>
import utils from '../functions/functions'
import { mapActions, mapGetters } from 'vuex'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'
import PostPrefetch from '../components/content/PostPrefetch'
import ToastBlock from '../components/integrations/ToastBlock.vue'
import TextBlock from '../components/integrations/TextBlock.vue'

export default {
  components: {
        BContainer, 
        BRow, 
        BCol,
        CarouselBlock,
        SimpleBlock,
        OverlayBlock,
        PostPrefetch,
        ToastBlock,
        TextBlock
    },   
  data(){
    return {
      needPrefetch: false
    }
  },
  computed: {
    ...mapGetters(['POSTS', 'PREFETCHED_CONTENT', 'PREFETCHED_FULL', 'LAST_ROUTE', 'POSTS']),
    siteContent: function(){
      return (utils.hasReferrer()) ? false : true
    },
    feedCarousel: function(){
      return (!utils.hasReferrer()) ? null : 'mixed'
    },
    feedSimple: function(){
      return (!utils.hasReferrer()) ? null : 'mixed'
    },
    feedOverlay: function(){
      //return (!utils.hasReferrer()) ? null : 'smi24'
      return (!utils.hasReferrer()) ? null : 'mixed'
    },
    dataFeed: function(){
      return {
        CarouselBlock: (utils.hasReferrer()) ? 'mixed' : 'site',
        SimpleBlock: (utils.hasReferrer()) ? 'mixed' : 'site',
        OverlayBlock: (utils.hasReferrer()) ? 'mixed' : 'site',
        ToastBlock: (utils.hasReferrer()) ? 'mixed' : 'site',
      }
    },
    hasRef: function(){
      return utils.hasReferrer()
    },
    goalName: function(){
      return (!utils.hasReferrer()) ? null : 'ClickFromHome'
    },
    limit: function(){
      return (utils.hasReferrer()) ? 0 : 30
    },
    isDirectHit: function(){
      return !utils.hasReferrer()
    }    
  },
  methods: {
    ...mapActions(['FETCH_LIST', 'TRACK_ROUTE', 'FETCH_SMI24', 'FETCH_INFOX', 'RESET_ALL', 'SMI2_FETCH', 'FEED_SUM_DEFINE', 'FEED_COUNT_RESET']),
  },
  created: function(){
    // this.FETCH_LIST('default')
    // this.RESET_ALL()
    // this.FEED_COUNT_RESET()
  },
  beforeMount(){
    // this.RESET_ALL()
  },
  mounted: function(){
    //если есть реф, но это не внутренний переход (обновил вкладку)
    if (utils.hasReferrer() && !this.LAST_ROUTE ){
      this.FEED_SUM_DEFINE(3)
      const vueContext = this
      this.FETCH_SMI24(vueContext)
      this.FETCH_INFOX(vueContext)
      this.SMI2_FETCH(vueContext)
    }else{
      //чекаем маршшрут
      //здесь фетчим в любом случае, варьируется только режим - из кэша или из интернетов
      //const cacheMode = (this.LAST_ROUTE) ? 'force-cache' : 'default'
      if (!this.POSTS.length){
        // const cacheMode = 'default'
        this.FETCH_LIST(/*cacheMode*/)    
      }

      this.needPrefetch = true

    }

  },
  beforeDestroy: function(){
    //трекаем маршрут
    this.TRACK_ROUTE(this.$route.name)
  },
  watch: {
  }
}
</script>
